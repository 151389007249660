(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/socket-with-alert/assets/javascripts/socket-with-toast.js') >= 0) return;  svs.modules.push('/components/components/socket-with-alert/assets/javascripts/socket-with-toast.js');
"use strict";


if (svs.core.detect.feature('ft-new-socket-impl')) {
  const dialogBranding = svs.core.detect.tenant('tb') ? svs.components.dialog.branding.TUR : svs.components.dialog.branding.SPORT;
  const toastReconnected = new svs.ui.dialog.Confirm({
    branding: dialogBranding,
    autoClose: svs.ui.dialog.autoClose.MEDIUM,
    icon: 'thumbs-up',
    area: svs.ui.dialog.area.TOASTER,
    title: 'Anslutningen till servern är nu återställd.',
    actions: [{
      title: 'OK'
    }]
  });
  const toastDisconnected = new svs.ui.dialog.Confirm({
    branding: dialogBranding,
    icon: 'exclamation-sign',
    area: svs.ui.dialog.area.TOASTER,
    title: 'För tillfället är det problem att ansluta till servern.',
    message: [{
      type: svs.ui.dialog.message.TEXT,
      text: 'Vi försöker att återansluta automatiskt. Vi ber om ursäkt för eventuella problem som detta kan orsaka.'
    }],
    actions: [{
      title: 'OK'
    }]
  });
  const toastDead = new svs.ui.dialog.Confirm({
    branding: dialogBranding,
    icon: 'exclamation-sign',
    area: svs.ui.dialog.area.TOASTER,
    title: 'Sidan uppdateras inte kontinuerligt.',
    message: [{
      type: svs.ui.dialog.message.TEXT,
      text: 'För att se senaste informationen behöver du ladda om sidan.'
    }],
    actions: [{
      title: 'Ok'
    }, {
      callback: () => {
        window.location.reload();
      },
      title: 'Försök igen'
    }]
  });
  const socketOptions = svs.core.socket.getOptions();
  const DEFAULT_SOCKET_DISCONNECTED_TOAST_ATTEMPTS = 3;
  const reconnectionAttempts = socketOptions.reconnectionAttempts === 0 ? DEFAULT_SOCKET_DISCONNECTED_TOAST_ATTEMPTS : socketOptions.reconnectionAttempts;
  const showOnAttempts = Math.floor(Math.min(Math.max(reconnectionAttempts / 2, 1), DEFAULT_SOCKET_DISCONNECTED_TOAST_ATTEMPTS));
  let isSocketDisconnected = false;
  svs.core.socket.on('connect', async () => {
    if (isSocketDisconnected) {
      svs.ui.dialog.api.add(toastReconnected);
      [toastDead, toastDisconnected].forEach(toast => {
        toast.close();
      });
    }
    isSocketDisconnected = false;
  });
  svs.core.socket.on('dead', async () => {
    svs.ui.dialog.api.add(toastDead);
    [toastReconnected, toastDisconnected].forEach(toast => {
      toast.close();
    });
  });
  svs.core.socket.on('reconnect_attempt', async attempt => {
    if (attempt >= showOnAttempts && !isSocketDisconnected) {
      svs.ui.dialog.api.add(toastDisconnected);
      [toastReconnected, toastDead].forEach(toast => {
        toast.close();
      });
      isSocketDisconnected = true;
    }
  });
}

 })(window);